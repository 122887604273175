import "./commentList.css";

import { useContext, useEffect, useState } from "react";

import { IAd, IComment, IUser } from "../../core/models/model";

import Firestore from "../../core/services/firestore";

import { setApplicationModal } from "../../core/slice/application";

import { useAppDispatch } from "../../utils/useStore";

import { SwishSpinner } from "react-spinners-kit";
import Factory from "../../core/services/factory";
import Grade from "../grade/grade";
import { UserContext } from "../../core/providers/user";
import useDevice from "../../utils/useDevice";
import { IReportType } from "../../modals/report/report";

interface ICommentListProps {
  ad: IAd;
}

interface ICommentProps {
  comment: IComment;
}

const Comment = function ({ comment }: ICommentProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchUser = async function (): Promise<void> {
      setLoading(true);

      const document = await Firestore.getUserDocumentFromFirestore(
        comment.owner
      );

      const owner = Factory.createUserFromFirebase(document.data());

      setUser(owner);

      setLoading(false);
    };

    fetchUser();
  }, []);

  const handleReportEvent = async function (): Promise<void> {
    dispatch(
      setApplicationModal({
        index: 0,
        props: {
          document: comment.id,
          title: "Signaler un avis",
          type: IReportType.COMMENT,
        },
      })
    );
  };

  return (
    <div className="comment-wrapper">
      {loading ? (
        <div className="comment-loading">
          <SwishSpinner frontColor="#FA8E20" />
        </div>
      ) : (
        <>
          <div className="comment-profile">
            <div>{user && <img src={user.avatar} alt="user profile" />}</div>
            <div>
              <p>{user && user.name}</p>
              <Grade grade={comment.grade} />
            </div>
          </div>
          <div className="message-comment">
            <p>{comment.message}</p>
          </div>
          <p onClick={() => handleReportEvent()}>Signaler le commentaire</p>
        </>
      )}
    </div>
  );
};

const CommentList = function ({ ad }: ICommentListProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [comments, setComments] = useState<IComment[]>([]);
  const [avisCount, setAvisCount] = useState<number>(ad.avis); // Track avis count
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  const isDeskot = useDevice();
  const fetchComments = async function (): Promise<void> {
    setLoading(true);

    const values: IComment[] = await Firestore.getCommentsAdsFromFirestore(
      ad.id
    );

    setComments(values);
    setAvisCount(values.length);
    setLoading(false);
  };
  useEffect(() => {
    fetchComments();
  }, []);

  const handleAddComment = function (): void {
    dispatch(
      setApplicationModal({
        index: 4,
        props: {
          onCommentAdded: () => {
            fetchComments(); // Fetch comments again after a new comment is added
            setAvisCount(avisCount + 1); // Increment avis count
          },
        },
      })
    );
  };

  return (
    <div className="comment-list-wrapper">
      <div className="comment-list-header">
        <p>{`Note & avis (${avisCount})`}</p>
        {user && ad.createdBy !== user!.id && (
          <p onClick={() => handleAddComment()}>
            {isDeskot ? "Rédiger un commentaire" : "Rédiger"}
          </p>
        )}
      </div>
      <div className="comment-list">
        {comments.map((comment, index) => (
          <Comment key={index} comment={comment} />
        ))}
      </div>
    </div>
  );
};

export default CommentList;
