export const priceKey = {
  monthly: "price_1QI6GGEYgTyBIziCYTUwLuvy",
  yearly: "price_1QI6GGEYgTyBIziCb2YpuvDG",
};

export const priceKeTest = {
  monthly: "price_1QIZAAEYgTyBIziCMGaSAAwX",
  yearly: "price_1QIZAAEYgTyBIziCumMwhYIh",
};

export const productKey = "prod_RAR8u0S1B8YZU5";

export const productKeyTest = "prod_RAv0hRPQ0qDXYj";
