import "./stripe.css";

import { useEffect, useState } from "react";

import { useContext } from "react";

import { UserContext } from "../../core/providers/user";
import { database } from "../../core/services/firebase";

import { loadStripe } from "@stripe/stripe-js";
import firebase from "firebase";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import Button from "../../components/button/button";
import useAuth from "../../utils/useAuth";
import { useAppDispatch } from "../../utils/useStore";
import { setApplicationModal } from "../../core/slice/application";

// const PUBLIC_KEY =
//   "pk_test_51Jgr2SEYgTyBIziCxSZ2x2l9MxSflHECIsuC3XQenH5kQOAy9afOKAne8N8GbZC9Xed2CbcpG4urH5o1Jij6Bly900wT2FniJf";
const PUBLIC_KEY =
  "pk_live_51Jgr2SEYgTyBIziCKGTny2Vg6oZVSg8Blq1hPcmVxWfByQeeLjGrLFdTvvrbEBqvaAp8hiId3MihUx9QhTptpcW500hEiaezV0";

const stripePromise = loadStripe(PUBLIC_KEY);

interface StripeModalProps {
  back?: boolean;
  callback?: Function;
}

const StripeModal = function ({
  back,
  callback,
}: StripeModalProps): JSX.Element {
  const [price, setPrice] = useState<number>(0);
  const [clientSecret, setClientSecret] = useState("");

  const { logout } = useAuth();

  const dispatch = useAppDispatch();

  const { user } = useContext(UserContext);

  const appearance = {
    theme: "flat",
    variables: {
      colorText: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    },
  } as any;

  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    var tmp = 0;

    const fetch = async function (): Promise<void> {
      const annonces = await database
        .collection("annonces")
        .where("createdBy", "==", user!.id)
        .get();

      for (var position = 0; position < annonces.docs.length; position++) {
        const annonce = annonces.docs[position].data();

        tmp +=
          0.1 * annonce.views + 0.1 * annonce.clicks + 1 * annonce.messages;
      }

      setPrice(tmp);

      if (tmp < 1.5 && back !== undefined && back) {
        firebase
          .auth()
          .currentUser!.delete()
          .then(async () => await logout());

        dispatch(setApplicationModal({ index: null, props: null }));
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    if (price < 1.5) {
      return;
    }

    const handlePayEvent = async function (): Promise<void> {
      var test = firebase.functions().httpsCallable("stripePayementFailure");

      test({
        price: price.toFixed(2).toString().split(".").join(""),
        customerID: user!.customerID,
      }).then((result: any) => {
        setClientSecret(result.data);
      });
    };

    handlePayEvent();
  }, [price]);

  return (
    <div className="modal-wrapper">
      <div className="stripe-modal-wrapper">
        <p>Un payement n'a pas pu étre effectué.</p>
        <p>Somme a payer : {price} €</p>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm back={back === undefined ? false : true} />
          </Elements>
        )}
        <div style={{ height: 10 }}></div>
        {back && (
          <Button
            title="Annuler"
            onClick={callback ? callback : () => {}}
            fill={false}
          />
        )}
      </div>
    </div>
  );
};

export default StripeModal;
