import firebase from "firebase";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../core/providers/user";
import { setApplicationModal } from "../../../core/slice/application";
import useDevice from "../../../utils/useDevice";

import { useAppDispatch } from "../../../utils/useStore";
import HeaderSettingsView from "./header";

import { IProfileViewProps } from "./profile";
import Input from "../../../components/input/input";
import Button from "../../../components/button/button";
import Drop from "../../../components/drop/drop";
import { priceKeTest, priceKey, productKeyTest } from "../../../env/Key";

import "./settings.css";
import { CANCEL_URL, SUCCESS_URL } from "../../../env/Const";

const SettingsProfile = function ({ setStep }: IProfileViewProps): any {
  // const isDeskot = useDevice();

  const { user } = useContext(UserContext);

  const dispatch = useAppDispatch();

  // const handleMoreEvent = async function (): Promise<void> {
  //   var test = firebase.functions().httpsCallable("updateCardToStripeCustomer");

  //   dispatch(setApplicationModal({ index: 1, props: null }));

  //   try {
  //     await test({
  //       customerID: user!.customerID,
  //       url: "https://happybati.web.app/",
  //     }).then((result: any) => {
  //       console.log("result " + JSON.stringify(result));

  //       window.location.href = result.data;
  //     });

  //     dispatch(setApplicationModal({ index: null, props: null }));
  //   } catch (error) {
  //     console.log("error in settings : handleMoreEvent " + error);
  //   }
  // };

  type SubscriptionType = "monthly" | "yearly";

  const [selectedPlan, setSelectedPlan] = useState<SubscriptionType>("monthly");

  const handlePlanChange = (plan: SubscriptionType) => {
    setSelectedPlan(plan);
  };

  const [promoCode, setPromoCode] = useState<string>("");

  const handleSubscriptionEvent = async function (): Promise<void> {
    const selectedPriceId = priceKey[selectedPlan];
    const createSubscriptionSession = firebase
      .functions()
      .httpsCallable("createSubscriptionSession");

    // Optionally, set loading or modal state here
    dispatch(setApplicationModal({ index: 1, props: null }));

    try {
      // if (promoCode !== "" && promoCode.length >= 5) {
      //   const result = await createSubscriptionSession({
      //     customerID: user!.customerID,
      //     email: user!.email,
      //     priceID: selectedPriceId,
      //     promotion_code: promoCode,
      //     success_url: SUCCESS_URL, // URL for successful payment
      //     cancel_url: CANCEL_URL, // URL if the user cancels
      //   });

      //   // Redirect the user to Stripe's checkout page
      //   window.location.href = result.data.url;
      // } else {
      const result = await createSubscriptionSession({
        customerID: user!.customerID,
        email: user!.email,
        priceID: selectedPriceId,
        success_url: SUCCESS_URL, // URL for successful payment
        cancel_url: CANCEL_URL, // URL if the user cancels
      });

      // Redirect the user to Stripe's checkout page
      window.location.href = result.data.url;
      // }
    } catch (error) {
      console.error("Error redirecting to Stripe checkout:", error);
      // Optionally, handle the error (e.g., show an error message to the user)
    } finally {
      dispatch(setApplicationModal({ index: null, props: null }));
    }
  };

  return (
    <div className="profile-menu-view">
      <HeaderSettingsView
        title="Moyen de paiement"
        // setStep={setStep}
        // more={isDeskot ? "Choisir une offre d'abonnement" : "Choisir"}
        // // moreClick={handleMoreEvent}
      />
      {user?.isSubscribed === undefined || user?.isSubscribed === false ? (
        <div className="subscription-container">
          <h3>Choisir un type d'abonnement</h3>
          {/* <p>
          We offer a monthly or annual subscription plan.{" "}
          <strong>Save $20 annually.</strong>
        </p> */}
          <div className="plans">
            <div
              className={`plan ${selectedPlan === "monthly" ? "selected" : ""}`}
              onClick={() => handlePlanChange("monthly")}
            >
              <p>Mensuel</p>
              <h3>39.00 €</h3>
              <input
                type="radio"
                checked={selectedPlan === "monthly"}
                readOnly
              />
            </div>
            <div
              className={`plan ${selectedPlan === "yearly" ? "selected" : ""}`}
              onClick={() => handlePlanChange("yearly")}
            >
              <p>Annuel</p>
              <h3>249.00 €</h3>
              <input
                type="radio"
                checked={selectedPlan === "yearly"}
                readOnly
              />
            </div>
          </div>
          {/* <Input
            title="Si vous avez un code promo remplisser le champ"
            placeholder="Code promo"
            value={promoCode} // Bind `tempCode` here
            onChanged={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPromoCode(e.target.value)
            }
            type="text"
            name="promoCode"
          /> */}
          <Button
            title="S'abonner"
            fill={true}
            onClick={handleSubscriptionEvent}
          />
          <br />
        </div>
      ) : (
        <Button
          title="Voir mon abonnement"
          fill={true}
          onClick={handleSubscriptionEvent}
        />
      )}
    </div>
  );
};

export default SettingsProfile;
