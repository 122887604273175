import "./search.css";

import SearchBar from "../searchBar/searchBar";

function Search() {
  return (
    <section className="search-section">
      <p>PROFESSIONNELS DU BÂTIMENT</p>
      <p>
        Fais briller ton entreprise, Révèle sa vraie puissance. Avec les
        meilleurs talents et fournisseurs, chaque projet devient une réussite
      </p>
      <SearchBar />
      <p>
        Peinture - Plomberie - Gros travaux - Charpente - Paysage - Électricité
        - Plaquiste
      </p>
    </section>
  );
}

export default Search;
