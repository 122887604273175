import "./footer.css";

import { NavLink, useHistory } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/home/happybati-footer.svg";
import { ReactComponent as LogoMobile } from "../../assets/home/happybati-footer-mobile.svg";

import { useContext } from "react";

import { UserContext } from "../../core/providers/user";

function Footer() {
  const { user } = useContext(UserContext);

  const history = useHistory();

  const handleRegisterEventClick = function (): void {
    history.push("/auth/register");
  };

  const handleLoginEventClick = function (): void {
    history.push("/auth/login");
  };

  const handleSearchClick = function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="footer-wrapper">
      <div>
        <div className="footer-left">
          <NavLink
            to="/home/search"
            className="footer-nav-link"
            onClick={handleSearchClick}
          >
            Rechercher
          </NavLink>
          {user === null && (
            <NavLink
              to="/auth/login"
              className="footer-nav-link"
              onClick={handleSearchClick}
            >
              Connexion
            </NavLink>
          )}
        </div>
        <div className="footer-center">
          <Logo className="logo" />
          <LogoMobile className="mobile-logo" />
          {user === null && (
            <div>
              <button onClick={handleRegisterEventClick}>Inscription</button>
              <button onClick={handleLoginEventClick}>Connexion</button>
            </div>
          )}
        </div>
        <div className="footer-right">
          <NavLink
            to="/cgu"
            className="footer-nav-link"
            onClick={handleSearchClick}
          >
            CGU
          </NavLink>
          <NavLink
            to="/cgv"
            className="footer-nav-link"
            onClick={handleSearchClick}
          >
            CGV
          </NavLink>
          <NavLink
            to="/politique"
            className="footer-nav-link"
            onClick={handleSearchClick}
          >
            Politique de confidentialité
          </NavLink>
          <NavLink
            to="/mentions"
            className="footer-nav-link"
            onClick={handleSearchClick}
          >
            Mentions légales
          </NavLink>
        </div>
      </div>
      <p>
        Copyright 2021 - HappyBati | Édité par{" "}
        <a target="blank" href="https://www.celaneo.com/">
          celaneo.com
        </a>
      </p>
    </div>
  );
}

export default Footer;
