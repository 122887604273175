import React, { useContext, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useAppDispatch } from "../../utils/useStore";
import { setApplicationModal } from "../../core/slice/application";
import { UserContext } from "../../core/providers/user";
import { database } from "../../core/services/firebase";
import firebase from "firebase";
import useAuth from "../../utils/useAuth";

export default function CheckoutForm({ back }: { back?: boolean }) {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);

  const { logout } = useAuth();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent!.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://happybati.web.app/",
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error?.message);
    } else {
      user!.stripeError = false;
      setUser(user);
      await database
        .collection("users")
        .doc(user!.id)
        .update({ stripeError: false });
      const annonces = await database
        .collection("annonces")
        .where("createdBy", "==", user!.id)
        .get();
      for (var position = 0; position < annonces.docs.length; position++) {
        await database
          .collection("annonces")
          .doc(annonces.docs[position].id)
          .update({ clicks: 0, views: 0, messages: 0 });
      }
      dispatch(setApplicationModal({ index: null, props: null }));
    }

    if (back !== undefined && back) {
      firebase
        .auth()
        .currentUser!.delete()
        .then(async () => await logout());
    }

    window.location.reload();

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="button-pay"
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
