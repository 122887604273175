import "./searchBar.css";

import { useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as Arrow } from "../../assets/icons/arrow-down.svg";

import { useAppDispatch } from "../../utils/useStore";

import { setApplicationModal } from "../../core/slice/application";
import { useHistory } from "react-router-dom";

import { SearchContext } from "../../core/providers/search";

import PrettoSlider from "../slider/slider";
import AdresseListed from "../adresselisted/adresselisted";
import SearchIcon from "@material-ui/icons/Search";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import Data from "../../data/activity.json";

import algoliasearch from "algoliasearch";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { CodeRounded } from "@material-ui/icons";

const client = algoliasearch("WSS4OWPZL5", "8779b6a63cfe3e91c277598116db29fd");
export const index = client.initIndex("annonces");

function SearchBar() {
  const { search, setSearch } = useContext(SearchContext);

  const [rayon, setRayon] = useState<number>(10);
  const [secteur, setSecteur] = useState<string>("");
  const [inputOpen, setInputOpen] = useState<boolean>(false);
  const [secteurOpen, setSecteurOpen] = useState<boolean>(false);
  const [lieuOpen, setLieuOpen] = useState<boolean>(false);
  const [rayonOpen, setRayonOpen] = useState<boolean>(false);

  const [secteurSearchList, setSecteurSearchList] = useState<
    { value: string; type: string }[] | null
  >(null);

  const [coord, setPosition] = useState<{
    lat: number;
    lng: number;
    adress: any;
  } | null>(null);

  const dispatch = useAppDispatch();

  const history = useHistory();

  const [historie, setHistorie] = useState<string[]>([]);

  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ debounce: 300 });

  var node = useRef<any>();

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseEvent, false);

    return () => {
      document.removeEventListener("mousedown", handleCloseEvent, false);
    };
  }, []);

  const handleCloseEvent = function (event: any): void {
    if (node && !node.current.contains(event.target)) {
      setInputOpen(false);
      setSecteurOpen(false);
      setLieuOpen(false);
      setRayonOpen(false);
    }
  };

  useEffect(() => {
    if (secteur === "") {
      setSecteurSearchList(null);

      return;
    }

    const result = Data.secteur.filter((record) =>
      record.value.toLowerCase().includes(secteur.toLowerCase())
    );

    setSecteurSearchList(result);
  }, [secteur]);

  useEffect(() => {
    if (inputOpen === false) {
      return;
    }

    const historique = localStorage.getItem("HBH");

    if (historique === null) {
      return;
    }

    const list = historique!.split("/");

    setHistorie(list!);
  }, [inputOpen]);

  const handleSelect =
    ({ description }: any) =>
    () => {
      clearSuggestions();

      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("📍 Coordinates: ", { lat, lng });
          setPosition({ lat: lat, lng: lng, adress: description });
          setValue(description);
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <AdresseListed
          adresse={main_text + " " + secondary_text}
          clickevent={handleSelect(suggestion)}
        />
      );
    });

  const handleSearchEvent = async function () {
    dispatch(setApplicationModal({ index: 1, props: null }));

    var response = null;

    if (coord !== null) {
      response = await index.search(search.type, {
        aroundLatLng: `${coord!.lat + ", " + coord!.lng}`,
        aroundRadius: rayon * 1000,
        hitsPerPage: 5,
        page: search.page - 1,
        filters: `${search.secteur && `secteur:${search.secteur}`}`,
        facetFilters: ["delete:false"],
      });
    } else if (coord === null) {
      response = await index.search(search.type, {
        hitsPerPage: 5,
        page: search.page - 1,
        filters: `${search.secteur && `secteur:${search.secteur}`}`,
        facetFilters: ["delete:false"],
      });
    }

    setSearch({
      ...search,
      hits: response!.hits,
      page: response!.page + 1,
      pages: response!.nbPages,
    });

    const historique = localStorage.getItem("HBH");

    if (historique === null) {
      localStorage.setItem("HBH", search.type);
    } else {
      const tmp = historique.concat("/", search.type);

      localStorage.setItem("HBH", tmp);
    }

    setInputOpen(false);

    dispatch(setApplicationModal({ index: null, props: null }));

    history.push("/home/search/custom");
  };

  const handleInputMenuEvent = function () {
    setInputOpen(!inputOpen);
    setSecteurOpen(false);
    setLieuOpen(false);
    setRayonOpen(false);
  };

  const handleSecteurMenuEvent = function (event: any) {
    if (event.target.classList.contains("secteur-select")) {
      event.stopPropagation();
      event.preventDefault();

      return;
    }

    setSecteurOpen(!secteurOpen);
    setLieuOpen(false);
    setRayonOpen(false);
    setInputOpen(false);
  };

  const handleLieuMenuEvent = function (e: any) {
    if (e.target.classList.contains("lieu-widget")) {
      e.stopPropagation();
      e.preventDefault();

      return;
    }

    setSecteurOpen(false);
    setLieuOpen(!lieuOpen);
    setRayonOpen(false);
    setInputOpen(false);
  };

  const handleRayonMenuEvent = function () {
    setSecteurOpen(false);
    setLieuOpen(false);
    setRayonOpen(!rayonOpen);
    setInputOpen(false);
  };

  const handleChange = function (event: any, newValue: any) {
    setRayon(newValue);
  };

  const handleClearHistorieEvent = function () {
    localStorage.removeItem("HBH");

    setHistorie([]);
  };

  const handleLieuSearchUp = async (e: any) => {
    setValue(e.target.value);
  };

  const clearSecteur = () => {
    setSecteur("");
    setSearch({ ...search, secteur: "" });
  };

  const clearLieu = () => {
    setValue("");
    setPosition(null);
  };

  return (
    <div className="search-bar-wrapper">
      <div className="search-bar-input-wrapper" ref={node}>
        <input
          placeholder="Matériaux, corps de métier.."
          onClick={handleInputMenuEvent}
          value={search.type}
          onChange={(event) =>
            setSearch({ ...search, type: event.target.value })
          }
        />
        <div className="secteur-wrapper">
          {search.secteur && (
            <HighlightOffIcon
              color="disabled"
              fontSize="small"
              onClick={clearSecteur}
            />
          )}
          {search.secteur ? (
            <p className="secteur-text">{search.secteur}</p>
          ) : (
            <p>Secteur</p>
          )}
          <Arrow onClick={handleSecteurMenuEvent} />
          {secteurOpen && (
            <div>
              <div className="secteur-select">
                <SearchIcon />
                <input
                  placeholder="Rechercher"
                  className="secteur-select"
                  value={secteur}
                  onChange={(event) => setSecteur(event.target.value)}
                />
              </div>
              <div>
                {secteurSearchList === null &&
                  Data.secteur.map((item, index) => (
                    <p
                      key={index}
                      onClick={() =>
                        setSearch({ ...search, secteur: item.value })
                      }
                    >
                      {item.value}
                    </p>
                  ))}
                {secteurSearchList &&
                  secteurSearchList.map((item, index) => (
                    <p
                      key={index}
                      onClick={() =>
                        setSearch({ ...search, secteur: item.value })
                      }
                    >
                      {item.value}
                    </p>
                  ))}
              </div>
            </div>
          )}
        </div>
        <div className="lieu-wrapper lieu-event" onClick={handleLieuMenuEvent}>
          {value ? (
            <>
              <HighlightOffIcon
                color="disabled"
                fontSize="small"
                onClick={clearLieu}
              />
              <p className="secteur-text">{`${value}`}</p>
            </>
          ) : (
            <p>Lieu</p>
          )}
          <Arrow />

          {lieuOpen && (
            <div>
              <div className="lieu-widget">
                <SearchIcon />
                <input
                  value={value}
                  onChange={handleLieuSearchUp}
                  className="lieu-widget"
                  placeholder="Rechercher"
                />
              </div>
              <div className="lieu-result-wrapper">
                {status === "OK" && renderSuggestions()}
              </div>
            </div>
          )}
        </div>
        <div className="rayon-wrapper" onClick={handleRayonMenuEvent}>
          {rayon === 0 ? <p>Rayon</p> : <p>{`< ${rayon}Km`}</p>}
          <Arrow />
          {rayonOpen && (
            <div>
              <div>
                <p>Rayon maximum</p>
                <p>{`< ${rayon}Km`}</p>
              </div>
              <PrettoSlider min={10} value={rayon} onChange={handleChange} />
            </div>
          )}
        </div>
        {inputOpen && (
          <div>
            <div>
              <p>Historique des recherches</p>
              <p onClick={() => handleClearHistorieEvent()}>Effacer</p>
            </div>
            <div>
              {historie.map((item, index) => (
                <p
                  key={index}
                  onClick={() => setSearch({ ...search, type: item })}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
      <button onClick={handleSearchEvent}>Rechercher</button>
    </div>
  );
}

export default SearchBar;
