import "./ads.css";

import { useHistory, useParams } from "react-router-dom";

import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../core/providers/user";

import Firestore from "../../core/services/firestore";
import Factory from "../../core/services/factory";
import Grade from "../grade/grade";

import { ReactComponent as Arrow } from "../../assets/icons/arrow-rigth.svg";

import { useAppDispatch } from "../../utils/useStore";

import { setApplicationModal } from "../../core/slice/application";

import { database } from "../../core/services/firebase";

import { useToasts } from "react-toast-notifications";

import firebase from "firebase";

import { IAd, IUser } from "../../core/models/model";

import { IReportType } from "../../modals/report/report";

interface IAdsProps {
  ad: IAd;
}

function Ads({ ad }: IAdsProps) {
  const { type } = useParams<{ type: string }>();

  const history = useHistory();

  const { user } = useContext(UserContext);

  const dispatch = useAppDispatch();

  const [parent, setParent] = useState<IUser | null>(null);

  const { addToast } = useToasts();

  useEffect(() => {
    const fetchUser = async function (): Promise<void> {
      const tmp = await Firestore.getUserDocumentFromFirestore(ad.createdBy);

      setParent(Factory.createUserFromFirebase(tmp.data()));
    };

    fetchUser();
  }, []);

  const handleSpeackEvent = async function () {
    if (user === null || parent === null || ad.createdBy === user!.id) {
      return;
    }

    try {
      console.log("ad");

      dispatch(setApplicationModal({ index: 1, props: null }));

      const document = await database
        .collection("channels")
        .where("annonce.id", "==", ad.objectID ? ad.objectID : ad.id)
        .where("parent", "==", user!.id)
        .get();

      console.log("ad");

      if (document.docs.length > 0) {
        await database
          .collection("channels")
          .doc(document.docs[0].id)
          .update({ date: firebase.firestore.Timestamp.fromDate(new Date()) });

        dispatch(setApplicationModal({ index: null, props: null }));

        history.push("/home/messages");

        return;
      }

      const information = {
        parent: user!.id,
        users: [user!.id, ad.createdBy],
        name: parent!.name,
        last: "",
        enable: true,
        avatar: parent!.avatar,
        annonce: {
          image: ad.image,
          title: ad.title,
          description: ad.description,
          id: ad.objectID ? ad.objectID : ad.id,
          hidePhoneNumber: ad.hidePhoneNumber,
        },
        date: firebase.firestore.Timestamp.fromDate(new Date()),
        block: false,
      };

      const newChanel = await database.collection("channels").add(information);

      await database
        .collection("annonces")
        .doc(ad.objectID ? ad.objectID : ad.id)
        .update({ messages: firebase.firestore.FieldValue.increment(1) });
      await database
        .collection("channels")
        .doc(newChanel.id)
        .collection("typings")
        .doc("typings")
        .set({ typings: [] });

      dispatch(setApplicationModal({ index: null, props: null }));

      history.push("/home/messages");
    } catch (error: any) {
      console.log("error in handleSpeackEvent ads.tsx : " + error);

      addToast("Une erreur indéfinie s'est produite.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleReportEvent = function (): void {
    dispatch(
      setApplicationModal({
        index: 0,
        props: {
          document: ad.id,
          title: "Signaler une annonce",
          type: IReportType.AD,
        },
      })
    );
  };

  const handleGoToAdsEvent = function (): void {
    history.push(`/home/search/${type}/ads/${ad.id ? ad.id : ad.objectID}`);

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSeeAdsEvent = function (): void {
    console.log(ad.id);

    history.push(
      `/home/search/${type}/ads/${ad.objectID ? ad.objectID : ad.id}`
    );

    window.scrollTo({ top: 1650, behavior: "smooth" });
  };

  return (
    <div className="ads-wrapperr">
      <div>
        <img src={ad.image} alt="ad show" />
      </div>
      <div className="ads-information-wrapperr">
        <div className="ads-type-status">HappyBati</div>
        <div className="custom-ads-information">
          <div className="ads-profilee">
            <div>{parent && <img src={parent.avatar} alt="avatar" />}</div>
            <div>
              {parent && <p>{parent.name}</p>}
              <div>
                {ad.grade !== 0 && <Grade grade={ad.grade} />}
                <p
                  onClick={() => handleSeeAdsEvent()}
                >{`Voir les avis (${ad.avis})`}</p>
              </div>
            </div>
          </div>
          <div className="text-info-wrapper">
            <p>{ad.title}</p>
            <p>{ad.description}</p>
          </div>
          <div className="ads-button-wrapper">
            <button
              onClick={handleSpeackEvent}
              style={{
                backgroundColor:
                  user !== null && ad.createdBy !== user!.id
                    ? "#F98D1F"
                    : "#C2C3C2",
              }}
              className="chat-button"
            >
              Envoyer un message
            </button>
            <button className="ads-button" onClick={() => handleGoToAdsEvent()}>
              Voir l’annonce
              <Arrow />
            </button>
          </div>
          <div className="report-button-wrapper">
            {user && ad.createdBy !== user!.id && (
              <button
                className="report-button"
                onClick={() => handleReportEvent()}
              >
                Signaler l’annonce
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ads;
