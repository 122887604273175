import { useContext, useState } from "react";

import Button from "../../../components/button/button";
import Drop from "../../../components/drop/drop";
import Input from "../../../components/input/input";

import HeaderSettingsView from "./header";

import useForm from "../../../utils/useForm";

import { IProfileViewProps } from "./profile";

import { database } from "../../../core/services/firebase";

import { UserContext } from "../../../core/providers/user";
import { useToasts } from "react-toast-notifications";

import Data from "../../../data/activity.json";

const HelpProfile = function ({ setStep }: IProfileViewProps): any {
  const { user } = useContext(UserContext);

  const { inputs, setInput, handleChangeByOne } = useForm({
    name: user!.name,
    message: "",
    choice: { value: "Problème paiement", type: "" },
  });

  const [loading, setLoading] = useState<boolean>(false);

  const { addToast } = useToasts();

  const handleSendMessage = async function (): Promise<void> {
    if (inputs.name === "" || inputs.message === "" || inputs.choice === null) {
      addToast("Veuillez remplir tous les champs comme demandé.", {
        appearance: "error",
        autoDismiss: true,
      });

      return;
    }

    setLoading(true);

    try {
      console.log("inputs.name ", inputs.name);
      console.log("inputs.choice ", inputs.choice);
      console.log("user!.id ", user!.id);
      console.log("inputs.message ", inputs.message);
      console.log("user!.email ", user!.email);

      await database.collection("mails").add({
        who: inputs.name,
        for: inputs.choice,
        owner: user!.id,
        message: inputs.message,
        email: user!.email,
      });

      addToast("Votre message a bien été envoyé.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error: any) {
      console.error("Error adding document to 'mails' collection:", error);
      addToast("Une erreur indéfinie s'est produite.", {
        appearance: "error",
        autoDismiss: true,
      });
    }

    setLoading(false);
  };

  return (
    <div className="profile-menu-view">
      <HeaderSettingsView title="Besoin d’aide ?" setStep={setStep} />
      <div className="profile-help invisible-scrollbar">
        <Input
          title="Nom et prénom"
          value={inputs.name}
          onChanged={setInput}
          name="name"
        />
        <Drop
          title="Sujet"
          choice={Data.sujet}
          selected={inputs.choice}
          onClick={handleChangeByOne}
          name="choice"
        />
        <div className="profile-help-area">
          <p>Message</p>
          <textarea
            placeholder="Votre message..."
            value={inputs.message}
            onChange={setInput}
            name="message"
          ></textarea>
        </div>
        <Button
          title={loading ? "Envoie en cours..." : "Envoyer le message"}
          fill={true}
          onClick={() => handleSendMessage()}
        />
      </div>
    </div>
  );
};

export default HelpProfile;
