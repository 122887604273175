import { selectApplicationModal } from "../../core/slice/application";

import { useAppSelector } from "../../utils/useStore";

import CardPayement from "../card/card";
import DeleteAccount from "../delete/delete";
import Loading from "../loading/loading";
import Report from "../report/report";
import Comment from "../comment/comment";
import Stripe from "../stripe/stripe";
import AccountState from "../account/account";
import DeleteAd from "../deleteAd/deleteAd";
import Monitor from "../monitor/monitor";

const ModalManager = function() : JSX.Element
{
    const application = useAppSelector(selectApplicationModal);

    switch (application.index)
    {
        case 0 : return <Report {...application.props} />;
        case 1 : return <Loading {...application.props} />;
        case 2 : return <DeleteAccount {...application.props} />
        case 3 : return <CardPayement {...application.props} />
        case 4 : return <Comment {...application.props} />
        case 5 : return <Stripe {...application.props} />
        case 6 : return <AccountState {...application.props} />
        case 7 : return <DeleteAd {...application.props} />
        case 8 : return <Monitor {...application.props} />
    }

    return (<></>);
}

export default ModalManager;