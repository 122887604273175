import {
  IAd,
  IBill,
  IChannel,
  IComment,
  IMessage,
  IUser,
} from "../models/model";

class Factory {
  // create user from firebase //

  public static createUserFromFirebase(information: any): IUser | null {
    var user: IUser;

    if (information === undefined) {
      return null;
    }

    user = {
      id: information.id,
      email: information.email,
      name: information.name,
      avatar: information.avatar,
      companie: information.companie,
      visibility: information.visibility,
      customerID: information.customerID,
      stripeError: information.stripeError,
      setupIntent: information.setupIntent,
      cardAdded: information.cardAdded,
      isSubscribed: information.isSubscribed,
      subscriptionStatus: information.subscriptionStatus,
      billing: information.billing,
    };

    return user;
  }

  // create message from firebase //

  public static createMessageFromFirebase(
    information: any,
    id: string
  ): IMessage | null {
    var message: IMessage;

    if (information === undefined) {
      return null;
    }

    message = {
      id: id,
      type: information.type,
      image: information.image,
      message: information.message,
      parent: information.parent,
    };

    return message;
  }

  // create ads from firebase //

  public static createAdsFromFirebase(
    information: any,
    id: string
  ): IAd | null {
    var ads: IAd;

    if (information === undefined) {
      return null;
    }

    ads = {
      objectID: "",
      id: id,
      makes: information.makes,
      title: information.title,
      types: information.types,
      image: information.image,
      createdBy: information.createdBy,
      grade: information.grade,
      status: information.status,
      date: information.date,
      avis: information.avis,
      description: information.description,
      price: information.price,
      rayon: information.rayon,
      views: information.views,
      messages: information.messages,
      clicks: information.clicks,
      dateEnd: information.dateEnd,
      secteur: information.secteur,
      hidePhoneNumber: information.hidePhoneNumber,
      adress: information.adress,
      _geoloc: information._geoloc,
    };

    return ads;
  }

  // create comment from firebase //

  public static createCommentFromFirebase(
    information: any,
    id: string
  ): IComment | null {
    var comment: IComment;

    if (information === undefined) {
      return null;
    }

    comment = {
      id: id,
      message: information.message,
      owner: information.owner,
      grade: information.grade,
    };

    return comment;
  }

  // create comment from firebase //

  public static createChannelFromFirebase(
    information: any,
    id: string
  ): IChannel | null {
    var channel: IChannel;

    if (information === undefined) {
      return null;
    }

    channel = {
      id: id,
      avatar: information.avatar,
      name: information.name,
      last: information.last,
      parent: information.parent,
      annonce: information.annonce,
      date: information.date,
      block: information.block,
      to: information.to,
      users: information.users,
    };

    return channel;
  }

  // create bills from firebase //

  public static createBillsFromFirebase(
    information: any,
    id: string
  ): IBill | null {
    var bill: IBill;

    if (information === undefined) {
      return null;
    }

    bill = { id: id };

    return bill;
  }
}

export default Factory;
