import './delete.css';

import firebase from "firebase";

import { useState } from "react";

import Button from "../../components/button/button";

import { setApplicationModal } from "../../core/slice/application";

import useModal from "../../utils/useModal";

import { useAppDispatch } from "../../utils/useStore";

import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { useToasts } from 'react-toast-notifications';
import StripeModal from '../stripe/stripe';

const DeleteAccount = function() : JSX.Element
{   
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const { addToast } = useToasts();

    const [step, setStep] = useState<number>(0);
    
    const handleDeleteEvent = async function() : Promise<void>
    {
        setLoading(true);

        try
        {
            setStep(1);
        }
        catch (error : any)
        {
            addToast("Une erreur indéfinie s'est produite.", { appearance: 'error', autoDismiss : true });
        }

        setLoading(false);
    }

    const handleCloseEvent = function() : void
    {
        dispatch(setApplicationModal({ index : null, props : null }));
    }

    const handleCancelEvent = async function() : Promise<void>
    {
        dispatch(setApplicationModal({ index : null, props : null }));
    }

    const step_1 = function()
    {
        return (
            <div className="modal-wrapper">
                <div className="delete-custom-modal-wrapper">
                    <div>
                        <h2>Suppression du compte</h2>
                        <Close onClick={ () => handleCloseEvent() } />
                    </div>
                    <p>Êtes-vous certain(e) de vouloir supprimer votre compte ?</p>
                    <div>
                        <Button title="Annuler" fill={false} onClick={handleCancelEvent} loading={loading} />
                        <Button title={ loading ? "Chargement..." : "supprimer" } fill={true} onClick={handleDeleteEvent} loading={loading} />
                    </div>
                </div>
            </div>
        );
    }

    const callback = function()
    {
        setStep(0);
    }

    const step_2 = function()
    {
        return <StripeModal back={true} callback={callback} />;
    }

    if (step === 0)
    {
        return step_1();
    }
    else
    {
        return step_2();
    }
}

export default DeleteAccount;