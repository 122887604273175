import "./comment.css";

import { useContext, useState } from "react";

import useModal from "../../utils/useModal";

import { ReactComponent as Close } from "../../assets/icons/close.svg";

import { useAppDispatch } from "../../utils/useStore";

import { setApplicationModal } from "../../core/slice/application";

import Button from "../../components/button/button";

import StarIcon from "@material-ui/icons/Star";

import useForm from "../../utils/useForm";

import { useToasts } from "react-toast-notifications";

import { database } from "../../core/services/firebase";

import { UserContext } from "../../core/providers/user";
import firebase from "firebase";

const Star = function ({ index, note, setNote }: any): JSX.Element {
  return (
    <StarIcon
      onClick={() => setNote(index)}
      style={{
        color: index <= note ? "#FA8E20" : "#E6E6E6",
        marginRight: 20,
        height: 30,
        width: 30,
        cursor: "pointer",
      }}
    />
  );
};

function Comment({ onCommentAdded }: { onCommentAdded: () => void }) {
  const id = window.location.href.split("/")[7];

  const [loading, setLoading] = useState<boolean>(false);

  const { node } = useModal();

  const dispatch = useAppDispatch();

  const [note, setNote] = useState<number>(1);

  const stars: any[] = [0, 0, 0, 0, 0];

  const { inputs, setInput } = useForm({ message: "" });

  const { user } = useContext(UserContext);

  const { addToast } = useToasts();

  const handleCloseEvent = function (): void {
    dispatch(setApplicationModal({ index: null, props: null }));
  };

  const handleAddCommentEvent = async function (): Promise<void> {
    if (inputs.message === "") {
      addToast("Veuillez remplir les champs comme demandé.", {
        appearance: "error",
        autoDismiss: true,
      });

      return;
    }

    if (!firebase.auth().currentUser) {
      console.error("User is not authenticated");
      return;
    }

    const uid = firebase.auth().currentUser?.uid;
    setLoading(true);

    try {
      dispatch(setApplicationModal({ index: 1, props: null }));

      await database
        .collection("annonces")
        .doc(id)
        .collection("avis")
        .add({
          avatar: user!.avatar,
          grade: note + 1,
          message: inputs.message,
          owner: uid,
        });

      addToast("Vous venez d'ajouté un commentaire.", {
        appearance: "success",
        autoDismiss: true,
      });

      // Call the callback to update the comments in CommentList
      onCommentAdded();

      dispatch(setApplicationModal({ index: null, props: null }));
    } catch (error) {
      console.log("error handleAddCommentEvent on comment.tsx : " + error);

      addToast("Une erreur s'est produite.", {
        appearance: "error",
        autoDismiss: true,
      });
    }

    setLoading(false);
  };

  return (
    <div className="modal-wrapper">
      <div className="comment-modal-wrapper" ref={node}>
        <div>
          <p>Laissez un commentaire</p>
          <Close onClick={() => handleCloseEvent()} />
        </div>
        <div>
          <p>Note (sur 5)</p>
          <div>
            {stars.map((star, index) => (
              <Star key={index} index={index} note={note} setNote={setNote} />
            ))}
          </div>
          <p>Commentaire</p>
          <textarea
            placeholder="Votre commentaire.."
            name="message"
            onChange={setInput}
            maxLength={193}
          ></textarea>
          <Button
            title={loading ? "Chargement..." : "Ajouter"}
            fill={true}
            onClick={() => handleAddCommentEvent()}
          />
          <Button
            title="Annuler"
            fill={false}
            onClick={() => handleCloseEvent()}
          />
        </div>
      </div>
    </div>
  );
}

export default Comment;
